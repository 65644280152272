

.exportBtn{
    color: white;
    font-weight: 600;
    background: rgb(20, 199, 65)
}
.exportBtn:hover{
    color: white;
}
.selectUl{
margin: 0px 10px 0px 0px;
color: var(--iq-primary);
text-align: left;
cursor: pointer;
text-decoration: underline;
margin-top: 8px;
}
.planDetails{
    border: 1px solid red;
    width: 100% !important;
}
