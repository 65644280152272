.get-valid-msisdn-btn {
    background-color: #2ecc71;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    font-family: 'Muli', sans-serif;
    border: none;
}

.get-valid-msisdn-btn-disabled {
    background-color: #95a5a6 !important;
}

.get-otp-btn {
    background-color: #2ecc71;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    font-family: 'Muli', sans-serif;
    border: none;
}