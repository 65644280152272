.avatar_div {
  background: #0081a2ad;
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  user-select: none;
}

.avatar_text {
  font-size: 4rem;
  color: #fff;
  margin: unset;
}

.iq_card {
  background: var(--iq-white);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  /* margin-bottom: 30px; */
  border: none;
  margin-bottom: 12px;


}

.iq_card_body {
  padding: 0 20px;
}

/* new css 24-1-24 */

.MyProfile_iq_card__KNFqW {
  /* background: var(--iq-white);
  border-radius: 15px;
  margin-bottom: 30px;
  border: none; */
}


/* new css 24-1-24 */