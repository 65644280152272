.imeiInputGroup {
  position: relative;
}

.imeiInputIcon {
  position: absolute;
  right: 15px;
  top: 38px;
  background: white;
}

.multilineIconBox {
  position: relative;
}

.multilineCloseIcon {
  font-size: 30px;
  color: #e74c3c;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0;
  line-height: 20px;
}

.timeline-item {
  align-items: flex-start;
  display: flex;
  position: relative;
}

ul .timeline-item {
  padding: 0 !important;
  margin: 0;
}


.timeline {
  color: #8094ae;
  line-height: 1.7;

}



.sessions-mvo {
  /* margin-top: 2rem; */
  border-radius: 12px;
  position: relative;
}

.timemvoline {
  padding-bottom: 0.8rem;
  border-left: 1px solid gray;

  position: relative;
  padding-left: 20px;
  margin-left: 10px;
}

.timemvoline:last-child {
  border: 0px;
  padding-bottom: 0;
}

.timemvoline:before {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  position: absolute;
  left: -10px;
  top: 0px;
  /* background: #e74c3c;
  border: 1px solid #e74c3c;
  box-shadow: 3px 3px 0px #e74c3c;
  box-shadow: 3px 3px 0px #e74c3c; */
}

.red::before {
  background: #e74c3c;
  border: 1px solid #e74c3c;
  box-shadow: 3px 3px 0px #f7a59c;
  box-shadow: 3px 3px 0px #f7a59c;

}

.green::before {
  background: #1EE0AC;
  border: 1px solid #1EE0AC;
  box-shadow: 3px 3px 0px #bab5f8;
  box-shadow: 3px 3px 0px #bab5f8;
}

.time-mvo {
  color: #2a2839;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

@media screen and (min-width: 601px) {
  .time-mvo {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 600px) {
  .time-mvo {
    margin-bottom: 0.3rem;
    font-size: 0.85rem;
  }
}



@media screen and (max-width: 600px) {
  p {
    font-size: 0.9rem;
  }
}

.timeline-titlemvno {
  padding-bottom: 0.7rem;
  padding-top: 2px;
  font-weight: 700;
}