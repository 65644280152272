.searchBtn {
    height: 37px !important;
    width: 100px;
    margin-top: -4px;
}

.exportBtn {
    color: white;
    font-weight: 600;
    background: rgb(20, 199, 65)
}

.exportBtn:hover {
    color: white;
}

.selectUl {
    margin: 0px 10px 0px 0px;
    color: var(--iq-primary);
    text-align: left;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 8px;
    padding: 0;
}
.activation-search-btn{
    margin-top: 26px !important;
  }